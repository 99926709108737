/** @format */

import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';

import icRoles from '@iconify/icons-ic/assignment-ind';
import icAdd from '@iconify/icons-ic/baseline-add-box';
import icPhone from '@iconify/icons-ic/baseline-add-ic-call';
import icReport from '@iconify/icons-ic/baseline-bar-chart';
import icCalendar from '@iconify/icons-ic/baseline-calendar-month';
import icAdmin from '@iconify/icons-ic/baseline-diversity-1';
import icEdu from '@iconify/icons-ic/baseline-menu-book';
import icMedia from '@iconify/icons-ic/baseline-photo-library';
import icSearch from '@iconify/icons-ic/baseline-search';
import icChat from '@iconify/icons-ic/chat-bubble';
import icHome from '@iconify/icons-ic/home';
import icLocation from '@iconify/icons-ic/location-on';
import icClients from '@iconify/icons-ic/people';
import icSettings from '@iconify/icons-ic/settings';
import icList from '@iconify/icons-ic/view-list';
import icAbout from '@iconify/icons-mdi/account-group';
import icAnnouncement from '@iconify/icons-mdi/announcement';
import icStaff from '@iconify/icons-mdi/badge-account-horizontal';
import icCalendarAccount from '@iconify/icons-mdi/calendar-blank';
import icCalendarCheck from '@iconify/icons-mdi/calendar-check';
import icCalendarEdit from '@iconify/icons-mdi/calendar-edit';
import icDirectory from '@iconify/icons-mdi/format-list-bulleted';
import icAudit from '@iconify/icons-mdi/list-box';
import icRx from '@iconify/icons-mdi/prescription';
import icSecurity from '@iconify/icons-mdi/security';
import icReward from 'src/app/shared/icons/myHelpLinkLogo';
import icChartAreaspline from '@iconify/icons-mdi/chart-areaspline';
import icHospital from '@iconify/icons-mdi/hospital-box';
import icFileDocumentMultiple from '@iconify/icons-mdi/file-document-multiple';
import icDownload from '@iconify/icons-mdi/download';

const sideNavItems: NavigationItem[] = [
  // unlisted but required for page title
  { type: 'link', label: 'menu.auth.forgot', route: '/auth/forgot-password', hidden: true },
  { type: 'link', label: 'menu.auth.login', route: '/auth/login', hidden: true },
  { type: 'link', label: 'menu.auth.reset', route: '/auth/reset-password', hidden: true },
  { type: 'link', label: 'menu.error.401', route: '/errors/401', hidden: true },
  { type: 'link', label: 'menu.error.404', route: '/errors/404', hidden: true },
  { type: 'link', label: 'menu.intake', route: '/intakeforms', hidden: true },
  { type: 'link', label: 'menu.schedule', route: '/scheduler', hidden: true },
  { type: 'link', label: 'menu.video', route: '/video/room', hidden: true },

  // side menu items
  {
    type: 'link',
    icon: icHome,
    label: 'menu.home',
    route: '/',
    routerLinkActiveOptions: { exact: true },
  },
  {
    type: 'link',
    icon: icChat,
    label: 'menu.chat',
    route: '/chat',
    authorizations: [
      'auth["User.isClient"]',
      'auth["User.isCMS"]',
      'auth["User.isPhysician"]',
      'auth["OptionLine.All"]',
      'auth["APRSMS.All"]',
    ],
  },
  {
    type: 'link',
    icon: icCalendar,
    label: 'menu.calendar.client',
    route: '/calendar',
    authorizations: ['auth["User.isClient"]'],
  },
  {
    type: 'dropdown',
    icon: icCalendar,
    label: 'menu.calendar.title',
    children: [
      {
        type: 'link',
        icon: icCalendarEdit,
        label: 'menu.calendar.edit',
        route: '/calendar/edit',
        authorizations: ['auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icCalendarCheck,
        label: 'menu.calendar.requests',
        route: '/calendar/requests',
        authorizations: ['auth["User.isCMS"]'],
      },
      {
        type: 'link',
        icon: icCalendarAccount,
        label: 'menu.calendar.staff',
        route: '/calendar',
        routerLinkActiveOptions: { exact: true },
        authorizations: ['auth["User.isCMS"]'],
      },
    ],
  },
  {
    type: 'link',
    icon: icMedia,
    label: 'menu.media',
    route: '/files/media',
    authorizations: ['auth["User.isClient"]'],
  },
  {
    type: 'link',
    icon: icReward,
    label: 'menu.rewards',
    route: '/rewards',
    authorizations: ['auth["User.isClient"] && org.TurnRewardsOn'],
  },
  {
    type: 'link',
    icon: icEdu,
    label: 'menu.educational',
    route: '/files/educational',
    authorizations: ['features.education && auth["User.isClient"]'],
  },
  {
    type: 'dropdown',
    icon: icClients,
    label: 'menu.client.title',
    children: [
      {
        type: 'link',
        icon: icAdd,
        label: 'menu.client.add',
        route: '/action',
        fragment: 'client-add',
        authorizations: ['auth["Client.Create"]'],
      },
      {
        type: 'link',
        icon: icAdd,
        label: 'menu.client.addAPRN',
        route: '/aprn/client/intake',
        authorizations: ['auth["APRTriage.All"]'],
      },
      {
        type: 'link',
        icon: icAudit,
        label: 'menu.client.audit',
        route: '/action',
        fragment: 'audit-client',
        authorizations: ['auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icCalendar,
        label: 'menu.client.schedule',
        route: '/ol/scheduler',
        authorizations: ['auth["OptionLine.All"]'],
      },
      {
        type: 'link',
        icon: icSearch,
        label: 'menu.client.search',
        route: '/client/search',
        authorizations: ['auth["APRSMS.All"]', 'auth["Client.Read"]'],
      },
    ],
  },
  {
    type: 'dropdown',
    icon: icStaff,
    label: 'menu.staff.title',
    children: [
      {
        type: 'link',
        icon: icAdd,
        label: 'menu.staff.add',
        route: '/staff/create',
        authorizations: ['auth["Application.Admin"]', 'auth["APR.All"]'],
      },
      {
        type: 'link',
        icon: icAudit,
        label: 'menu.staff.audit',
        route: '/action',
        fragment: 'audit-staff',
        authorizations: ['auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icDirectory,
        label: 'menu.staff.directory',
        route: '/staff/directory',
        authorizations: ['auth["Application.Admin"]', 'auth["APR.All"]'],
      },
      {
        type: 'link',
        icon: icRoles,
        label: 'menu.staff.roles',
        route: '/staff/roles',
        authorizations: ['auth["Application.Admin"]', 'auth["APR.All"]'],
      },
      {
        type: 'link',
        icon: icSearch,
        label: 'menu.staff.search',
        route: '/staff/search',
        authorizations: ['auth["Staff.Search"]'],
      },
    ],
  },
  {
    type: 'dropdown',
    icon: icAdmin,
    label: 'menu.admin.title',
    children: [
      {
        type: 'link',
        icon: icAnnouncement,
        label: 'menu.admin.announcements',
        route: '/admin/announcements',
        authorizations: ['auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icSecurity,
        label: 'menu.access.title',
        route: '/access',
        authorizations: [
          'features.accessControl && auth["Application.Admin"] && (org.TrackDevice || org.TrackLocation)',
        ],
      },
      {
        type: 'link',
        icon: icSecurity,
        label: 'menu.admin.authorizations',
        route: '/admin/authorizations',
        authorizations: ['auth["Application.Admin"] '],
      },
      {
        type: 'link',
        icon: icRx,
        label: 'menu.admin.ePrescribe',
        route: '/action',
        fragment: 'e-prescribe-admin',
        authorizations: ['features.todo && auth["APR.All"]', 'features.todo && auth["Application.All"]'],
      },
      {
        type: 'link',
        label: 'menu.admin.educational',
        route: '/admin/educational',
        icon: icEdu,
        authorizations: ['features.todo && features.education && auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icList,
        label: 'menu.admin.values',
        route: '/admin/values',
        authorizations: ['auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icLocation,
        label: 'menu.admin.locations',
        route: '/admin/locations',
        authorizations: ['auth["Application.Admin"]'],
      },
      {
        type: 'link',
        icon: icPhone,
        label: 'menu.admin.poolPhones',
        route: '/admin/poolPhones',
        authorizations: ['auth["Application.All"]'],
      },
      {
        type: 'link',
        icon: icSettings,
        label: 'menu.admin.org',
        route: '/admin/org',
        authorizations: ['auth["Application.Admin"]'],
      },
    ],
  },
  {
    type: 'link',
    icon: icHospital,
    label: 'menu.report.Medical (BETA)',
    route: '/report/legacy',
    authorizations: ['auth["Application.Reports"]'],
  },
  {
    type: 'dropdown',
    icon: icChartAreaspline,
    label: 'menu.report.Client Reports',
    children: [
      {
        type: 'link',
        label: 'menu.report.dashboard',
        route: '/report/dashboard', 
        authorizations: ['auth["Application.Dashboard"]'], 
      },
    ],
  },
  {
    type: 'dropdown',
    icon: icFileDocumentMultiple,
    label: 'menu.report.Management Reports',
    children: [],
  },
  {
    type: 'link',
    icon: icDownload,
    label: 'menu.report.aprData',
    route: '/aprn/reports',
    authorizations: ['auth["APR.All"]'],
  },
  {
    type: 'link',
    icon: icAbout,
    label: 'menu.about',
    route: '/about',
    authorizations: ['auth["User.isClient"]'],
  },
];

export { sideNavItems };
