/** @format */

export const environment = {
  apiKeys: {
    vapid: 'BDK45_3kCo91Vtru3nGSd6Y6Q478lKe_3jjlD6vwH_EgOqbQfy_H7mTBWq_2U21qu1Vf_fH0DxCako0Ch5Sl8OE',
    hCaptcha: '34fcb9b9-2c29-4ab9-b40c-cf840d997558',
  },
  aprnOrgId: '65596e97-0a26-4a68-9bb2-02490c51c69b',
  ePrescribe: {
    admin: 'https://spu.scriptsure.com',
    host: 'https://ssu.scriptsure.com',
  },
  features: {
    accessControl: true,
    education: false,
    fileId: true,
    reports: true,
    showChosenGenderSetting: true,
    todo: false,
    viewPoolPhones: false,
  },
  host: 'https://hbi-cms-staging.azurewebsites.net',
  production: true,
  role: {
    MEDICAL: {
      '8786c55d-a838-4097-9640-14ad1d1263f2': 'APR Nurse',
      '80563040-71bc-4bc5-8fde-24b673151b45': 'Center Medical',
    },
    PHYSICIAN: {
      'eaa27f6d-9288-45e0-9ceb-f23de5d06df7': 'Physician',
      '642e3358-483e-4671-ab70-377ebd3f59ae': 'Physician (Location Limited)',
    },
    PROVIDER: {
      'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx': 'HBI Provider',
    },
    PROVIDER_EXTERNAL: {
      '614ddad1-8838-42b4-bf47-c95ac531be1d': 'APR Provider',
    },
    CLIENT: {
      '51503e13-5738-4d0c-be63-1db2b0d41c41': 'Client',
    },
  },
  version: '25.02.10-b2e2a7db',
  reportCategories: {
    'Client Reports': {
      type: 'dropdown', // This is a dropdown for Client Reports
      reportName: ['Clients', 'Activities', 'Outcomes', 'Client Follow Up - BETA', 'Client Births - BETA', 'Appointment Reason - BETA', 'Activities (BETA)']
    },
    'Management Reports': {
      type: 'dropdown', // This is a dropdown for Management Reports
      reportName: ['Donors', 'Administrative', 'Center Activity', 'Trends', 'ELG', 'National Organization', 'Case Status - BETA', 'Rejected Appointment Requests - BETA', 'Tier Invoicing' ]
    },
    otherReports: {
      type: 'link', // These are for single reports to display on the side nav
      reportName: ['Medical (BETA)']
    }
  },
};
